<script lang="ts" setup>
import {toTypedSchema} from '@vee-validate/zod';
import * as z from 'zod';
import {useField} from 'vee-validate';
import {Search} from 'lucide-vue-next'
import type {HTMLAttributes} from 'vue'
import {cn} from '@/lib/utils'
import type {DialCode} from "~/types/dictionary";

const statesStore = useStatesStore();
const PhoneCodes = useDictionaryStore();
const userProfile = useUserProfileStore()

const {t} = useI18n()
const props = defineProps({
    showVerifyLabel: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
    },
    class: {
        type: String as PropType<HTMLAttributes['class']>
    },
    input_class: {
        type: String as PropType<HTMLAttributes['class']>
    },
    required: {
        type: Boolean,
        default: true
    },
    defaultDialCode: {
        type: Object as PropType<DialCode>,
    },
    name: {
        type: String,
        default: 'phone_number'
    }
})

const phoneMask = computed(() => statesStore.CurrentDialCode?.phone_mask || "(###) ### ####")
const requiredDigitsCount = computed(() => phoneMask.value.replace(/[^#]/g, '').length)

const usedPlaceholder = computed(() => {
    return props.placeholder || statesStore.CurrentDialCode?.phone_mask?.replaceAll('#', '_')
})

const validationSchema = ref()
validationSchema.value = z.string()
    .refine((value) => {
        const rawValue = value.replace(/\D/g, '');
        return rawValue.length === requiredDigitsCount.value;
    }, {
        message: t('Phone number must contain exactly {count} digits', requiredDigitsCount.value),
    })

if (!props.required) {
    validationSchema.value = validationSchema.value.optional()
}

const {value: phoneNumber, errorMessage, setValue} = useField(props.name, toTypedSchema(validationSchema.value));

const dialCodeId = ref()
const DialCodes = PhoneCodes.CodePhoneList
const open = ref(false)
const items = ref(DialCodes);
const searchQuery = ref('');
const filteredItems = computed(() => {
    if (!searchQuery.value) {
        return items.value;
    }
    return items.value.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
});

watch(dialCodeId, (value) => {
    statesStore.CurrentDialCode = DialCodes.find(item => item.id === value) || null;
})

function applyPhoneMask(value: string, mask: string): string {
    let cleanedValue = value.replace(/\D/g, ''); // Убираем все символы, кроме цифр
    let maskedValue = '';
    let maskIndex = 0;
    let valueIndex = 0;

    while (maskIndex < mask.length && valueIndex < cleanedValue.length) {
        if (mask[maskIndex] === '#') {
            maskedValue += cleanedValue[valueIndex];
            valueIndex++;
        } else {
            maskedValue += mask[maskIndex];
        }
        maskIndex++;
    }

    return maskedValue;
}

const handleInputPhoneNumber = (event: Event) => {
    const inputElement = event.target as HTMLInputElement;

    // Применяем маску к введённому значению
    const rawValue = inputElement.value.replace(/\D/g, ''); // Только цифры
    const maskedValue = applyPhoneMask(rawValue, phoneMask.value); // Применяем маску
    setValue(maskedValue, true);
    statesStore.UserPhone = rawValue;
};

onMounted(() => {
    if (props.defaultDialCode) {
        dialCodeId.value = props.defaultDialCode.id
    }
})
</script>

<template>
    <UiFormField v-slot="{ componentField }" :name="name">
        <UiFormItem :class="cn('relative', props.class)">
            <UiFormLabel>
                <div class="flex justify-between items-center mb-1">
                    <p>{{ $t("Phone Number") }} <span v-if="required" class="text-primaryblue-500">*</span></p>
                    <template v-if="showVerifyLabel">
                        <p v-if="!userProfile.PhoneNumber" class="ltr:ml-4 rtl:mr-4 ys-text-verify flex">{{
                                $t("Verify")
                            }}
                            <Icon name="ri:arrow-right-up-line" size="16"/>
                        </p>
                        <p v-if="userProfile.PhoneNumber" class="ltr:ml-4 rtl:mr-4 ys-text-verified flex">
                            {{ $t("Verified") }}
                            <Icon name="ri:check-line" size="16"/>
                        </p>
                    </template>
                </div>
            </UiFormLabel>
            <UiFormControl>
                <div :class="cn('relative !mt-0 flex border border-gray-200 rounded-xl box-sizing overflow-hidden', props.input_class)">
                    <UiSelect v-model="dialCodeId">
                        <UiSelectTrigger
                            class="!ring-0 !ring-offset-0 rtl:flex-row-reverse justify-between font-normal text-sub text-sm h-[44px] border-t-0 border-b-0 border-s-0 rounded-none w-full max-w-24 px-2"
                        >
                            <div class="flex items-center rtl:flex-row-reverse">
                                <UiSelectValue placeholder="+971"/>
                            </div>
                        </UiSelectTrigger>
                        <UiSelectContent>
                            <UiSelectItem v-for="item in filteredItems"
                                          :value="item.id" class="hover:bg-stroke">
                                <Icon :name="'circle-flags:' + item?.flag?.toLowerCase()" class="ltr:mr-2 rtl:ml-2" size="18"/>
                                <span>{{ item.dial_code }}</span>
                            </UiSelectItem>
                        </UiSelectContent>
                    </UiSelect>
<!--                <span
                    class="ltr:border-r rtl:border-l flex items-center text-center justify-center px-2 top-0.5 text-sm">
                  <UiButton
                      id="menu-button"
                      :class="{ custom: open }"
                      aria-expanded="true"
                      aria-haspopup="true"
                      class="dc-selector-button"
                      style="font-size: 14px"
                      type="button"
                      @click="open = !open"
                  >
                    <Icon :name="'circle-flags:' + statesStore.CurrentDialCode?.flag?.toLowerCase()" class="mr-1"
                          size="18"/>
                    {{ statesStore.CurrentDialCode?.dial_code }}
                    <Icon class="text-zinc-800" name="ri:arrow-down-s-line" size="18"/>
                  </UiButton>
                </span>-->
                    <UiInput
                        :disabled="disabled"
                        :placeholder="usedPlaceholder"
                        class="ys-form-input ps-2 pe-2 border-none !rounded-s-none rounded-e-xl max-h-auto h-full"
                        type="tel"
                        v-bind="componentField"
                        @input="handleInputPhoneNumber"
                    />
                </div>
                <UiFormMessage/>
            </UiFormControl>
        </UiFormItem>
    </UiFormField>

    <div v-if="open" aria-labelledby="menu-button" aria-orientation="vertical"
         class="dc-selector-body mt-0 mb-8 col-span-full"
         role="menu"
         tabindex="-1">
         <div class="relative">
            <UiInput id="search" v-model="searchQuery" class="ys-search-input border-none w-full ltr:pl-8 rtl:pr-8"
                     placeholder="Search..."
                     type="text"/>
            <span class="absolute start-0 inset-y-0 flex items-center justify-center">
                <Search class="size-4 text-gray-500"/>
              </span>
        </div>
        <div class="">
            <UiSeparator class="mb-1"/>
        </div>
        <UiScrollArea style="height: 150px">
            <div v-for="item in filteredItems" :key="item.dial_code" class="py-0.5" @click="open = false">
                <p class="locales_list text-gray-700 px-1 py-2 text-sm cursor-pointer flex items-center text-center "
                   role="menuitem" tabindex="-1" @click="statesStore.CurrentDialCode = item">
                    <Icon :name="'circle-flags:' + item?.flag?.toLowerCase()" class="ltr:mr-2 rtl:ml-2" size="18"/>
                    {{ item.name }}
                </p>
            </div>
        </UiScrollArea>
    </div>
</template>

<style lang="scss" scoped>
.dc-selector-button {
    border: 0;
    color: rgba(82, 88, 102, 1);
    background: unset;
    text-decoration: unset;
    border-radius: 8px;
    font-size: 14px;
    height: 36px;
    padding: 8px 0 8px 0;
    gap: 4px;
}

.ys-search-input {
    width: 360px;
    height: 36px;
    padding: 8px 10px 10px 30px;
    gap: 8px;
    border-radius: 8px;
    border: none;
}

.ys-search-input:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.ys-text-verified {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
    color: rgba(45, 159, 117, 1);
}

.ys-text-verify {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
    color: rgba(242, 174, 64, 1);
}

</style>